// SECTION headers config ======================================
export const BE_CONTROLLER_VERSION = 'v1';
export const dashboardVersion = 212;
export const dashboardVerHeaderForBE = 'X-Dashboard-Version';
export const dashboardVerHeaderForFE = 'x-dashboard-version';
export const updateAvailableHeader = 'x-dashboard-version-type';
export const optionalUpdate = 'optional';
export const mandatoryUpdate = 'mandatory';
export const DMS_CHANNEL = 'Dashboard';

export const DMS_CHANNEL_HEADER_FOR_BE = 'X-Dms-Channel';
export const AUTH_TOKEN_HEADER_FOR_BE = 'X-Auth-Token';
export const USER_MOBILE_HEADER_FOR_BE = 'X-Account-Id';
export const CSRF_TOKEN_FOR_BE = 'X-CSRF-Token';

export const AUTH_TOKEN_HEADER_FOR_FE = 'x-auth-token';
export const CSRF_TOKEN_FOR_FE = 'x-csrf-token';

export const RECENT_USED_TENANT = 'recent-used-tenant';
